import React, { useState } from "react";
import "../App.css"; 
import { Link } from "react-router-dom";
import reminisImg from "../images/rmns.png";
import appVideo from "../images/rmns.mp4";

const FeaturedWork = () => {
  // State for tracking hover effect for each project individually
  const [hoveredProject, setHoveredProject] = useState(null);

  // Mouse enter function (for specific project)
  const handleMouseEnter = (projectId) => {
    setHoveredProject(projectId); 
  };

  // Mouse leave function
  const handleMouseLeave = () => {
    setHoveredProject(null); // Reset the hover state
  };

  return (
    <section className="md:py-20 md:px-8 bg-black text-white max-w-[1300px] m-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        
        {/* First work item (Image with inner scaling effect) */}
        <div className="space-y-4 overflow-hidden relative">
          <Link
            to="/project/reminis"
            onMouseEnter={() => handleMouseEnter(1)} 
            onMouseLeave={handleMouseLeave}
            className="group"
          >
            <div className="overflow-hidden">
              <img
                src={reminisImg}
                alt="Reminis App"
                className="w-full transform group-hover:scale-110 transition-transform duration-300"
              />
            </div>

            {/* Overlay "View Project" text within the circle */}
            {hoveredProject === 1 && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="rounded-full w-28 h-28 bg-white bg-opacity-30 flex items-center justify-center">
                  <span className="text-white text-xs font-semibold text-center">
                    View Project
                  </span>
                </div>
              </div>
            )}
          </Link>
          <p className="text-xs text-left m-0 text-gray-400">
            Branding. UX/UI. Product/App Design.
          </p>
          <Link to="/project/reminis">
            <h3 className="md:text-2xl text-xl py-6 text-left font-sans max-w-[450px] hover:text-gray-300">
              Reminis—The app designed to reconnect you with your cherished
              memories.
            </h3>
          </Link>
        </div>

        {/* Second work item (Video with inner scaling effect) */}
        <div className="space-y-4 overflow-hidden relative">
          <Link
            to="/project/read"
            onMouseEnter={() => handleMouseEnter(2)} 
            onMouseLeave={handleMouseLeave}
            className="group"
          >
            <div className="overflow-hidden">
              <video
                className="w-full transform group-hover:scale-110 transition-transform duration-300"
                autoPlay
                loop
                muted
                playsInline
              >
                <source src={appVideo} type="video/mp4" />
              </video>
            </div>

            {/* Overlay "View Project" text within a circle */}
            {hoveredProject === 2 && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="rounded-full w-28 h-28 bg-white bg-opacity-30 flex items-center justify-center">
                  <span className="text-white text-xs font-semibold text-center">
                    View Project
                  </span>
                </div>
              </div>
            )}
          </Link>
          <p className="text-xs text-left m-0 text-gray-400">
            Branding. UX/UI. Web Design.
          </p>
          <Link to="/project/read">
            <h3 className="md:text-2xl text-xl py-6 text-left font-sans max-w-[400px] hover:text-gray-300">
              Read—Redefining the reading experience through product design and dev.
            </h3>
          </Link>
        </div>
      </div>

      <Link
        to="/work"
        className="group flex items-center space-x-2 text-white font-semibold text-1xl border-current pb-2 font-sans transition duration-300 ease-in-out hover:text-gray-400"
      >
        View all work
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z"></path>
        </svg>
      </Link>
    </section>
  );
};

export default FeaturedWork;
