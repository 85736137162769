import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="max-w-[1300px] m-auto flex justify-between items-center py-10 px-8 md:px-0 bg-black text-white">
      <div className="text-2xl">
        <NavLink to="/" className="hover:text-gray-500 transition duration-300 ease-in-out ">
          al.ma
        </NavLink>
      </div>
      <div className="md:space-x-16 space-x-4 md:text-base text-xs">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? "text-white" : "text-gray-400 hover:text-gray-200 transition duration-300 ease-in-out "
          }
        >
          home.
        </NavLink>
        <NavLink
          to="/work"
          className={({ isActive }) =>
            isActive ? "text-white" : "text-gray-400 hover:text-gray-200 transition duration-300 ease-in-out "
          }
        >
          work.
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) =>
            isActive ? "text-white" : "text-gray-400 hover:text-gray-200 transition duration-300 ease-in-out "
          }
        >
          contact.
        </NavLink>
      </div>
    </nav>
  );
};

export default Navbar;
