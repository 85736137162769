import React from 'react';
import reminisSignUp from "../images/reminis/reminis-signup.png";

const ProjectHero = ({ titleProject, type, industry, contribution, imageSrc, concept, challenge, challengeDesc, challengeImage, challengeImage1}) => {
  return (
    <section className="bg-black text-white md:py-12 md:px-8">
      <div className="max-w-[1305px] mx-auto">
        {/* Project Title */}
        <h1 className="text-2xl md:text-6xl font-sans md:mb-24 mb-5 max-w-[1100px]">{titleProject}</h1>
        {/* Project Info */}
        <div className="flex flex-col md:flex-row justify-between text-gray-400 text-sm ">
          <p>Type: <span className="text-white">{type}</span></p>
          <p>Industry: <span className="text-white">{industry}</span></p>
          <p>Contribution: <span className="text-white">{contribution}</span></p>
        </div>
        {/* Project Cover Image */}
        <div className="mt-4">
          <img src={imageSrc} alt={titleProject} className="w-full" />
        </div>
        {/* Concept Section */}
        <div className="mt-6 flex justify-between flex-col md:flex-row">
          <h4 className="text-sm text-left font-semibold mb-5">Concept</h4>
          <p className="text-sm text-white max-w-[400px]">{concept}</p>
        </div>
        {/* Challenge Section */}
        <div className="md:mt-24 mt-5 flex justify-between flex-wrap ">
          <div>
          <h4 className="text-sm text-left font-semibold">Challenge</h4>
          <p className="text-sm text-white max-w-[400px] md:mt-6 mt-4 mb-12">{challengeDesc}</p>
          <img src={challengeImage1} alt="titleProject" className="w-full max-w-[500px] object-contain mb-10" />
          </div>
            <img src={challengeImage} alt="titleProject" className="w-full max-w-[550px] object-contain" />
          
        </div>
      </div>
    </section>
  );
};

export default ProjectHero;
