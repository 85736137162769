import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import WorkPage from "./pages/WorkPage";
import ContactPage from "./pages/ContactPage";
import ProjectPage from "./pages/ProjectPage";
import Homepage from "./pages/Homepage";
import "./App.css";



function App() {
  return (
    <Router>
      <div className="App bg-black text-white min-h-screen">
        <Navbar /> {/* Navbar stays here */}
        <div className="max-w-[1350px] xl:max-w-[1450px] mx-auto px-8">
          <Routes>
            {/* Homepage route */}
            <Route path="/" element={<Homepage />} />
            {/* Work page route */}
            <Route path="/work" element={<WorkPage />} />
            {/* Contact page route */}
            <Route path="/contact" element={<ContactPage />} />
            {/* Project page route */}
            <Route path="/project/:projectId" element={<ProjectPage />} />

          </Routes>
        </div>
        <div className="max-w-[1350px] mx-auto px-8">
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
