
import React, { useEffect } from 'react';
import { useParams, } from 'react-router-dom';
import ProjectHero from '../components/ProjectHero';
import ProjectFooter from '../components/ProjectFooter';
import NextProject from '../components/NextProject';
import { projects } from '../data/projects';
import reminisKeyQuestions from '../images/reminis/reminis-home-dark.png';
import reminisStartChat from '../images/reminis/reminis-start-chat.png';
import reminisUploadFiles from '../images/reminis/reminis-file-upload.png';
import buxtHomeImg from '../images/buxt/home.png';
import buxtToolkitImg from '../images/buxt/toolkit.png';
import buxtEssenceImg from '../images/buxt/essence.png';
import buxtGameImg from '../images/buxt/gamee.png';
import buxtPersonaImg from '../images/buxt/persona.png';
import buxtGamerImg from '../images/buxt/gamer.png';
import buxtDashboardImg from '../images/buxt/dashboard.png';
import buxtLogoImg from '../images/buxt/logo.png';
import saBlogImg from '../images/securelyattached/blog.png';
import saExercisesImg from '../images/securelyattached/exercises.png';
import saStylesImg from '../images/securelyattached/styles-carousel.png';
import enriiShop from '../images/enrii/shop.png';
import enriiCart from '../images/enrii/cart.png';
import enriiNewsletter from '../images/enrii/newsletter.png';
import enriiRoutine from '../images/enrii/routine.png';
import enriiEssenceImg from '../images/enrii/essence.png';
import enriiMistImg from '../images/enrii/mist.png';
import rkBulushImg from '../images/rk/blush.png';
import rkThreeImg from '../images/rk/three.png';
import rkHomeImg from '../images/rk/home.png';
import rkHome1Img from '../images/rk/home1.png';
import klubPinkImg from '../images/klub/pink.png';
import klubWhiteImg from '../images/klub/white.png';
import calmImg from '../images/klm/calm.png';
import klmImg from '../images/klm/home.png';
import klmOasisImg from '../images/klm/oasis.png';
import klmVistaImg from '../images/klm/vista.png';

const ProjectPage = () => {
    const { projectId } = useParams();   
    const project = projects.find((p) => p.slug === projectId); 

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [projectId]);
    if (!project) {
        return <div>Project not found</div>;
    }
  
    const nextProjectIndex = (projects.findIndex(p => p.id === project.id) + 1) % projects.length;
    const nextProject = projects[nextProjectIndex];

  return (
 
    <div>
      <ProjectHero
        titleProject={project.titleProject}
        type={project.type}
        industry={project.industry}
        contribution={project.contribution}
        imageSrc={project.coverImage}
        concept={project.concept}
        description={project.description}
        challenge={project.challenge}
        challengeDesc={project.challengeDesc}
        challengeImage={project.challengeImage}
        challengeImage1={project.challengeImage1}
      />
      
      {/* Custom content for Reminis */}
      {project.slug === 'reminis' && (
        <div className="custom-content max-w-[1300px] mx-auto">
            <div className="mt-6 flex flex-col md:flex-row justify-between">
          <h2 className="text-sm text-left font-semibold mb-4">Key Questions</h2>
          <p className=" text-sm max-w-[400px]">How can the app provide emotional support while respecting ethical boundaries? / What are the potential psychological risks, and how can the app mitigate them? / Can the app serve as a therapeutic tool without delaying natural grief processes?.</p>
          </div>
          <img src={reminisKeyQuestions} alt="reminis app homepage darkmode" className="w-full max-w-[1000px] md:mt-36 mt-10 mx-auto" />
      
            <div className="max-w-[1300px] mx-auto md:mt-36 mt-10">
                <h2 className="text-3xl text-left mb-6 max-w-[400px]">Ethnographic and behavioral research.</h2>
                <div className="flex flex-start flex-col md:flex-row">
                <p className=" text-sm max-w-[400px] md:pr-12">I began the research process with an extensive ethnographic study focusing on how different cultures and communities process grief in the digital age. From desk research and contextual inquiry, I learned that people already memorialize loved ones through social media, digital archives, and more recently, AI-driven tools such as "ghostbots". </p>
            <p className=" text-sm max-w-[400px] mt-4">In addition, behavioral research on grief cycles allowed me to understand key moments when users may need technological support. I studied existing tools in grief counseling and how users respond to stimuli that recreate memories, identifying the emotional triggers that could guide the AI-based interactions. My research unearthed a critical insight: while grief is universal, digital interactions can vary significantly by culture and age group, therefore I took a highly personalized approach for the app design.</p>
                </div>
                <div className="flex justify-between gap-12 flex-col md:flex-row">
            <img src={reminisStartChat} alt="titleProject" className="w-full max-w-[1000px] md:mt-36 mt-10 mx-auto" />
            <img src={reminisUploadFiles} alt="titleProject" className="w-full max-w-[500px] md:mt-36 mx-auto object-contain" />
                </div>
                <div className="flex flex-col md:mt-24 mt-10 flex-wrap gap-4">
            <h4 className="text-sm text-left font-semibold max-w-[400px]">Target group - 3 segments</h4>
            <div className="flex  flex-col md:flex-row justify-between gap-2">
                <p className=" text-sm max-w-[300px] ">Unresolved grief, fear of forgetting their loved one, social isolation.</p>
           
            
            <p className=" text-sm max-w-[300px]">Anxiety about future loss of memory, discomfort in having no tangible archive to pass on..</p>
            <p className=" text-sm max-w-[300px]">Emotional fatigue, difficulty moving through stages of grief, mental health concerns..</p>
        </div>
             </div>
          </div>
          <div className="separator border-t md:mt-20 mt-10 max-w-[1300px] mx-auto"></div>
          <h2 className="text-sm font-semibold text-left md:mt-36 mt-10 mb-6">Journey mapping & emotional arc</h2>
                <div className="flex flex-start">
                <p className=" md:text-xl max-w-[650px] md:pr-12">To make sure the app aligned with users’ emotional states, I incorporated emotional arc analysis into the UX design. This allowed me to predict emotional peaks (e.g., moments of joy or closure) and valleys (e.g., feelings of sorrow) in the user journey. Once I identified the emotional peaks and valleys, I was able to strategically introduce moments of comfort during peaks and limit potential emotional overload during valleys. </p>
                </div>
             
        </div>
      )}
      {/* Custom content for BUXT */}
      {project.slug === 'buxt' && (
        <div className="custom-content max-w-[1300px] mx-auto">
         <p className=" text-xl mt-10 md:mt-0 max-w-[500px]">How might I help Indie Developers get their games on the market and have a chance at competing with big-budget AAA studios?
         </p>
         <img src={buxtHomeImg} alt="buxt app challenge " className="w-full max-w-[1200px] md:mt-24 mt-10 mx-auto" />
         <p className=" text-sm max-w-[400px] md:mt-24 mt-10">Through this toolkit, developers gain access to strategic branding guidance, UX principles, and practical templates—all presented in a format they can easily implement. The toolkit is available through a presentation website and includes resources like Notion documents and Figma visuals, with a dashboard currently under development.  
        </p>
       
        <img src={buxtToolkitImg} alt="buxt app toolkit " className="w-full  md:mt-24 mt-10 mx-auto" />
      
      <div className="md:mt-24 mt-10 flex justify-between flex-col md:flex-row">
          <h4 className="text-sm text-left font-semibold mb-4">Research & Concept Development</h4>
          <p className="text-sm text-white max-w-[400px]"> I conducted interviews, market analysis, and studied existing literature to understand the unique challenges faced by indie developers. It became clear that developers needed a toolkit that was not only practical and affordable but also easy to integrate into their existing workflows.</p>
        </div>
        <img src={buxtGamerImg} alt="buxt app persona " className="w-full max-w-[1200px] md:mt-24 mt-10 mx-auto" />
        <h2 className="text-3xl text-left mb-6 md:mt-24 mt-10 max-w-[400px]">The toolkit creation</h2>
                <div className="flex flex-start flex-col md:flex-row">
                <p className=" text-sm max-w-[400px] md:pr-12">I created branding and UX templates in Figma, as well as detailed strategic guides in Notion. The toolkit guides developers through the process of creating strong brand identities, refining user journeys, and ensuring their games resonate with players on an emotional level. </p>
            <p className=" text-sm max-w-[400px] mt-4 md:mt-0">To make these resources accessible, I structured them as step-by-step guides. This way, even developers with little to no prior branding or UX experience can follow along and apply the concepts to their games immediately.</p>
                </div>
                <div className="flex flex-col md:flex-row justify-between gap-12 max-w-[1300px] mx-auto">
                <img src={buxtEssenceImg} alt="buxt app essence" className="w-full max-w-[300px] md:mt-24 mt-10 object-contain" />
                <img src={buxtGameImg} alt="buxt app dashboard " className="w-full md:mt-24  max-w-[900px] object-contain" />
                </div>
        <p className=" text-sm max-w-[400px] md:mt-24 mt-10">The dashboard is currently under development but is intended to provide an interactive space where developers can track their progress, save resources, and customize their branding and UX strategies. The design of the dashboard follows the same user-centric principles as the website, prioritizing ease of use and visual clarity.</p>
        <img src={buxtPersonaImg} alt="buxt app persona " className="w-full max-w-[1200px] md:mt-24 mt-10 mx-auto" />
        <img src={buxtDashboardImg} alt="branding and us toolkit app dashboard screen" className="w-full max-w-[1200px] md:mt-24 mt-10 mx-auto" />
        <img src={buxtLogoImg} alt="buxt app logo " className="w-full max-w-[900px] md:mt-24 mt-10 mx-auto" />
        </div>
      )}
     {/* Custom content for SecurelyAttached */}
     {project.slug === 'securelyattached' && (
        <div className="custom-content max-w-[1300px] mx-auto">
            <div className="mt-6 flex flex-col md:flex-row justify-between">
          <h2 className="text-sm text-left font-semibold mb-4">How it works </h2>
          <p className=" text-sm max-w-[400px]">Users begin by taking an attachment style quiz that identifies their attachment patterns. Based on their results, they are guided through a personalized healing journey that includes mindfulness exercises, journaling prompts, and reflective practices tailored to their attachment style.</p>
          </div>
          <img src={saBlogImg} alt="securelyattached app homepage darkmode" className="w-full max-w-[1300px] md:mt-36 mt-10 mx-auto" />
      
            <div className="max-w-[1300px] mx-auto md:mt-36 mt-10">
                <h2 className="text-3xl text-left mb-6 max-w-[400px]">So... what's the problem</h2>
                <div className="flex flex-start flex-col md:flex-row">
                <p className=" text-sm max-w-[400px] md:pr-12">People with insecure attachment styles often struggle to maintain healthy relationships. They face challenges such as anxiety, emotional avoidance, and difficulty trusting others, making it hard for them to experience fulfilling connections. How might we create a safe, supportive digital space where they can heal these attachment wounds? </p>
            <p className=" text-sm max-w-[400px] mt-4">How might we create a digital platform that guides users through healing their attachment styles, fostering secure relationships?</p>
                </div>
                <div className="flex justify-between gap-12 flex-col md:flex-row">
            <img src={saExercisesImg} alt="projectTitle" className="w-full max-w-[800px] md:mt-36 mt-10 mx-auto object-contain" />
            <img src={saStylesImg} alt="projectTitle" className="w-full max-w-[500px] md:mt-36 mx-auto object-contain" />
                </div>
                <div className="flex flex-col md:mt-24 mt-10 flex-wrap gap-4">
            <h4 className="text-sm text-left font-semibold max-w-[400px]">Why are projects like this important?</h4>
            <div className="flex  flex-col md:flex-row justify-between">
                <p className=" text-sm max-w-[300px] mb-4 ">Attachment theory is a critical component of understanding human relationships, yet most resources around it are too academic or inaccessible to the average person. SecurelyAttached bridges that gap by offering a practical, accessible platform for healing relationships and improving emotional health.</p>
            <p className=" text-sm max-w-[300px]">Users often come to the platform feeling emotionally exhausted and confused about why their relationships don’t work. They need a structured yet gentle approach that guides them toward healing without overwhelming them.</p>
        </div>
             </div>
          </div>
          <div className="separator border-t md:mt-20 mt-10 max-w-[1300px] mx-auto"></div>
          <h2 className="text-sm font-semibold text-left md:mt-36 mt-10 mb-6">If there was a sequel.</h2>
                <div className="flex flex-start">
                <p className=" md:text-xl max-w-[650px] md:pr-12">Given more time, I would integrate more community features, such as peer-to-peer support groups or mentorship programs, to create a stronger sense of connection among users. </p>
                </div>
             
        </div>
      )}
       {/* Custom content for ENRIICO */}
       {project.slug === 'enriico' && (
        <div className="custom-content max-w-[1300px] mx-auto">
         
         <img src={enriiShop} alt="shop enrii ecommerce " className="w-full max-w-[1200px] md:mt-24 mt-10 mx-auto" />
       
       
        <img src={enriiCart} alt="enrii ecommerce " className="w-full  md:mt-24 mt-10 mx-auto" />
      
     
          <p className="text-xl text-white max-w-[450px] mt-12"> The checkout process was optimized to reduce cart abandonment, and to ensure a smooth transition from browsing to purchasing.</p>
        
        <img src={enriiNewsletter} alt="enrii ecommerce design " className="w-full max-w-[1200px] md:mt-24 mt-10 mx-auto" />
       
              
                <div className=" flex  flex-col md:flex-row justify-between gap-12 max-w-[1300px] mx-auto">
                <img src={enriiEssenceImg} alt="enrii ecommerce" className="w-full max-w-[600px] md:mt-24 mt-10 object-contain" />
                <img src={enriiMistImg} alt="enrii ecommerce webshop" className="w-full  max-w-[500px] md:mt-24 object-contain" />
                </div>
        <p className=" text-sm max-w-[400px] md:mt-24 mt-10">Aligning the scientific aspects of the brand with the emotional, mindful approach to beauty. The challenge was to create a digital and physical experience that felt sophisticated without feeling clinical.</p>
        <img src={enriiRoutine} alt="enrii ecommerce " className="w-full max-w-[1200px] md:mt-24 mt-10 mx-auto" />
        </div>
      )}
      {/* Custom content for RK */}
      {project.slug === 'rk' && (
        <div className="custom-content max-w-[1300px] mx-auto">
            <div className="mt-6 flex flex-col md:flex-row justify-between">
            <img src={rkBulushImg} alt="rk labels" className="w-full max-w-[600px] md:mt-24 mt-10 mx-auto object-contain" />
            <img src={rkHomeImg} alt="rk labels" className="w-full max-w-[400px] md:mt-24 mt-10 mx-auto object-contain" />
          </div>
          <img src={rkThreeImg} alt="rk labels branding " className="w-full max-w-[1200px] md:mt-24 mt-10 mx-auto" />
          <img src={rkHome1Img} alt="rk labels branding " className="w-full max-w-[1000px] md:mt-24 mt-10 mx-auto" />
        </div>
      )}
       {/* Custom content for klub */}
       {project.slug === 'klub' && (
        <div className="custom-content max-w-[1300px] mx-auto">
            <div className="max-w-[1300px] mx-auto md:mt-36 mt-10">
                <div className="flex  flex-col md:flex-row justify-between md:gap-12">
            <img src={klubPinkImg} alt="reminis app start chat screen design" className="w-full max-w-[600px] md:mt-36 mx-auto" />
            <img src={klubWhiteImg} alt="reminis app file upload screen design" className="w-full max-w-[400px] md:mt-36 mt-10 mx-auto object-contain" />
                </div>
                <div className="flex flex-col mt-24 flex-wrap gap-12">
             </div>
          </div>
                <p className=" text-xl max-w-[650px] pr-12">More about this project coming soon. </p>
        </div>
      )}
       {/* Custom content for read */}
       {project.slug === 'read' && (
        <div className="custom-content max-w-[1300px] mx-auto">
<p className=" text-xl max-w-[650px] mt-10">More about this project coming soon. </p> 
        </div>
      )}
       {/* Custom content for KLM ARCH */}
       {project.slug === 'klm-arch' && (
        <div className="custom-content max-w-[1300px] mx-auto">
         
         <img src={calmImg} alt="calm blog post " className="w-full max-w-[1200px] md:mt-24 mt-10 mx-auto" />
       
         <img src={klmImg} alt="klm  website " className="w-full  md:mt-24 mt-10 mx-auto" />
        
       
                <div className="flex justify-between gap-12 max-w-[1300px] mx-auto">
                <img src={klmOasisImg} alt="calm blog post redesign" className="w-full max-w-[600px] md:mt-24 mt-10 object-contain" />
                <img src={klmVistaImg} alt="web blog post redesign" className="w-full md:mt-24 mt-10 max-w-[500px] object-contain" />
                </div>
              
        </div>
      )}
      <ProjectFooter 
      footerImage={project.footerImage}
        testimonialText={project.testimonialText}
        testimonialAuthor={project.testimonialAuthor}
        authorRole={project.authorRole}
        authorCompany={project.authorCompany}
        
      />
      <div className="separator border-t md:mt-20 mt-10 max-w-[1300px] mx-auto"></div>
      <NextProject nextProject={nextProject} />
    </div>
  );
};

export default ProjectPage;
