import React from 'react';
import { Link } from 'react-router-dom';

const NextProject = ({ nextProject }) => {
  return (
    <section className="bg-black text-white px-8">
      <div className="max-w-[1305px] mx-auto mt-12">
        {/* Next Project Image */}
        <div className="flex flex-col md:flex-row justify-between md:items-start">
          <div className="flex flex-col md:flex-row  md:items-start mb-8 md:mb-0">
            <p className="text-sm text-gray-400 mb-2 md:mb-0 md:mr-4">Next Project</p>
            <div className="flex flex-col">
              <Link to={`/project/${nextProject.slug}`}>
                <img
                  src={nextProject.coverImage}
                  alt={nextProject.title}
                  className="w-full max-w-[350px] md:max-w-[550px] mb-4 md:mb-0"
                />
              </Link>
              <Link to={`/project/${nextProject.slug}`}>
                <h2 className="md:text-2xl text-xl max-w-[300px] md:max-w-[500px] mt-2">
                  {nextProject.title}
                </h2>
              </Link>
            </div>
          </div>

          <div className="mt-4 md:mt-0 md:ml-auto">
            <Link
              to="/work"
              className="group flex items-center space-x-2 text-white font-semibold text-1xl border-current pb-2 font-sans transition duration-300 ease-in-out hover:text-gray-400"
            >
              View all work
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z"></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NextProject;
