import React from "react";

// Import the SVG icons
import rebrandingIcon from "../images/icon-rebranding.svg";
import designIcon from "../images/icon-design.svg";
import positioningIcon from "../images/icon-positioning.svg";

const MyApproach = () => {
  return (
    <section className="py-10 bg-black text-white max-w-[1300px] m-auto">
      <div class="separator border-t py-4"></div>
      <h2 className="text-1xl font-sans mb-10"> My approach</h2>
      <div className="flex flex-col md:flex-row flex-start md:gap-20 gap-4">
        {/* Service 1 */}
        <div className="space-y-4 text-center md:text-left">
          {/* SVG icon for Rebranding */}
          <div className="flex  justify-start">
            <img
              src={rebrandingIcon}
              alt="Rebranding Icon"
              className="w-10 h-10 mb-4"
            />
          </div>
          <h3 className="text-3xl font-sans mb-2 max-w-[279px] text-left">
            Rebranding That Doesn’t Suck
          </h3>
          <p className="text-sm font-sans text-gray-300 leading-relaxed text-left">
            If your brand feels stale, it’s because it is.The market’s changed,
            and you’re stuck with an outdated identity. I don’t just slap a new
            logo on your biz; I rebuild your brand from the ground up to make
            people take notice.
          </p>
        </div>

        {/* Service 2 */}
        <div className="space-y-4 text-left">
          {/* SVG icon for Design */}
          <div className="flex justify-start">
            <img
              src={designIcon}
              alt="Design Icon"
              className="w-10 h-10 mb-4"
            />
          </div>
          <h3 className="text-3xl font-sans mb-2 max-w-[379px] text-left">
            Design That Converts (Not Just Looks Pretty)
          </h3>
          <p className="text-sm font-sans text-gray-300 leading-relaxed text-left">
            If your e-commerce site looks nice but sales are flat, it’s probably
            because your UX sucks, and your brand doesn’t inspire trust. I’ll
            optimize the user experience and give your site a makeover that
            actually drives conversions.
          </p>
        </div>

        {/* Service 3 */}
        <div className="space-y-4 text-left">
          {/* SVG icon for Positioning */}
          <div className="flex justify-start">
            <img
              src={positioningIcon}
              alt="Positioning Icon"
              className="w-10 h-10 mb-4"
            />
          </div>

          <h3 className="text-3xl font-sans mb-2 max-w-[379px] text-left">
            Positioning That Cuts Through the Noise
          </h3>
          <p className="text-sm font-sans text-gray-300 leading-relaxed text-left">
            If customers don’t care about your brand, you’re blending in when
            you should be standing out. I’ll help you own your market space so
            people start paying attention.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MyApproach;
